import { render, staticRenderFns } from "./travelHistory.vue?vue&type=template&id=526811d7&scoped=true&lang=html&"
import script from "./travelHistory.vue?vue&type=script&lang=js&"
export * from "./travelHistory.vue?vue&type=script&lang=js&"
import style0 from "./travelHistory.vue?vue&type=style&index=0&id=526811d7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526811d7",
  null
  
)

export default component.exports